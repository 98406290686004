<template>
  <div id="app" class="dashboard db-">
    <div class="mobile-flag d-block d-sm-none w-100"></div>
    <b-alert v-if="alert"
            :show="showAlert"
            :variant="alertType"
            dismissible
            fade
            @dismissed="clearAlert"
    >{{alert}}
    </b-alert>


    <background-process />

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
    'background-process': () => import('@/components/BackgroundProcess.vue'),
  },
  data: () => ({
    msgServer: null,
    sseSource: undefined,
  }),
  beforeDestroy() {
    if (this.msgServer) {
      this.msgServer.close();
    }
  },
  created() {
    let storedUser = localStorage.getItem('user');
    if (storedUser) {
      storedUser = JSON.parse(storedUser);
      if (storedUser.login_type) {
        this.$store.dispatch('app/runApp');
      } else {
        this.$store.dispatch('app/runApp');
        this.$store.dispatch('refs/getAll');
      }
    }

    this.$root.$on('bv::modal::shown', this.handleModalOpen);

  },
  mounted() {
    this.$store.commit('app/setIsMobile');
  },
  methods: {
    clearAlert() {
      this.$store.dispatch('alert/clear');
    },
    handleModalOpen(bvEvent, modalId) {
      if (!(bvEvent.vueTarget.$attrs['fixed-header'] && this.$store.state.app.isMobile)) return;
      const modalWindow = document.querySelector('body>[id$="modal_outer_"]');
      if (!modalWindow) {
        return;
      }

      const modalHeader = modalWindow.querySelector('.modal-header');
      modalHeader.classList.add('fixed-pos');

      const modalContent = modalWindow.querySelector('.modal-content');
      if (modalContent) {
        modalContent.style.paddingTop = modalHeader.offsetHeight + 'px';
      }
    }
  },
  computed: {
    showAlert() {
      if (this.alert) {
        return this.alertType==='error' ? 20 : 10;
      }
      return false;
    },
    alert() {
      console.log('this.$store.state.alert.message',this.$store.state.alert.message);
      return this.$store.state.alert.message;
    },
    alertType() {
      return this.$store.state.alert.type;
    },
  },
  watch: {
    $route() {
      // clear alert on location change
      this.$store.dispatch('alert/clear');
    },
  },
};
</script>

<template>
  <div>
    <template v-if="isEditing">
      <span>{{value}}</span>
    </template>
    <template v-else>
      <value-item
              v-bind:value="value"
              v-on:input="emitValue"
              :selectOptions="allClients"
              :editable="user.isAdmin"
              :addonIcon="'user-plus'"
              @addonAction="newRefItem('clients', 'Client Name')"
              @blur="emitBlur"
      ></value-item>
    </template>

    <new-ref-item ref="newRefItem"></new-ref-item>
  </div>

</template>

<script>
import ValueItem from '@/components/ValueItem.vue';
import NewRefItem from '@/components/newRefItem.vue';

export default {
  name: 'RefClientsSelector',
  props: {
    cont: {
      required: false,
    },
    value: {
      required: false,
    },
    rowData: {
      required: false,
    },
    field: {
      required: false,
    },
    index: {
      required: false,
    },
    editing: {
      required: false,
    },
  },
  mounted() {
    // console.log('sadfasf', this.rowData, this.field, this.index);
  },
  components: { ValueItem, NewRefItem },
  computed: {
    isEditing() {
      if (this.cont && this.cont.cellEditing_) {
        // console.log('isEditing', this.cont);
        return this.cont.cellEditing_;
      }
      return this.editing;
    },
    user() {
      return this.$store.state.authentication.user;
    },
    allClients() {
      let allItems = this.$store.state.refs.clients || [];

      allItems = allItems.length ? allItems : [];
      allItems = allItems.length
        ? allItems.map(item => {
            return {
              value: item.id,
              text: item.name,
            };
          })
        : [];

      return allItems;
    },
  },
  methods: {
    newRefItem(newRefName, newRefTitle) {
      this.$refs.newRefItem.show(newRefName, newRefTitle);
    },
    emitValue: function(e, v) {
      this.$emit('input', e, v);
      this.$emit('on-custom-comp', {
        rowData: this.rowData,
        field: this.field,
        value: e,
      });
    },
    emitBlur: function() {
      this.$emit('cell-blur');
    },
  },
};
</script>

<style scoped>
</style>

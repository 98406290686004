import Vue from "vue";
import App from "./App.vue";
import { router } from "@/_helpers";

import {appService} from "@/_services";
appService.globalPatch();


import { store } from '@/_store';
import { google_client_id } from 'config';

import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/main.scss';

import GoogleAuth from 'vue-google-oauth';
Vue.use(GoogleAuth, { client_id: google_client_id });
Vue.googleAuth().load();

Vue.config.productionTip = false;

import RefClientsSelector from './components/RefClientsSelector.vue';
Vue.component('RefClientsSelector', RefClientsSelector);


import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

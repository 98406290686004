import { userService } from '../_services';

export const users = {
  namespaced: true,
  state: {
    users: {},
    clients: {},
    error: '',
  },
  actions: {
    getAll({ commit }) {
      commit('getAllRequest');
      userService.getAll().then(
        users => commit('getAllSuccess', users),
        error => commit('getAllFailure', error)
      );
    },
  },
  mutations: {
    getAllRequest(state) {
      state.users = { loading: true };
    },
    getAllSuccess(state, users) {
      // debugger
      state.users = { items: users.filter(e => !e.login_type) };
      state.clients = { items: users.filter(e => e.login_type) };
    },
    getAllFailure(state, error) {
      state.users = { error };
    },
  },
};

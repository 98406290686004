export const filter = {
  namespaced: true,
  state: {
    filterValues: {},
  },
  actions: {},
  mutations: {
    setFilter(state, data) {
      state.filterValues = Object.assign(state.filterValues, data);
    },
  },
};

<template>
  <div>
    <b-modal ref="newItemModal"
             hide-footer
             title="Create new reference item"
             @shown="inputFocus"
    >
      <form @submit.stop.prevent="newItemAdd">
        <value-item
                v-bind:value="newRefItemName"
                v-on:input="newRefItemName = $event"
                :inputType="'text'"
                :valueTitle="newRefTitle"
                :editable="true"
        ></value-item>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'newRefItem',
  components: {
    'value-item': () => import('@/components/ValueItem.vue'),
    // spinner: SwappingSquaresSpinner,
  },
  props: {
    id: {
      type: String,
    },
  },
  data: () => ({
    newRefItemName: '',
    newRefName: '',
    newRefTitle: '',
  }),
  methods: {
    newRefItem(newRefName, newRefTitle) {
      this.newRefItemName = '';
      this.newRefName = newRefName;
      this.newRefTitle = newRefTitle;
      this.$refs.newItemModal.show();
    },
    newItemAdd() {
      console.log('new ref item in ' + this.newRefName, this.newRefItemName);
      this.$store.dispatch('refs/newItem', {
        refName: this.newRefName,
        refData: { name: this.newRefItemName },
      });
      this.$refs.newItemModal.hide();
      this.$emit('created');
    },
    show(newRefName, newRefTitle) {
      this.newRefName = newRefName;
      this.newRefTitle = newRefTitle;
      this.$refs.newItemModal.show();
    },
    inputFocus() {
      this.$refs.newItemModal.$el.querySelector('input').focus();
    },
  },
  computed: {},
};
</script>

<style scoped>
</style>

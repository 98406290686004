<template>
  <div class="jumbotron">
    <div class="container">
      <div class="form-holder">
        <div class="img-holder text-center">
          <img src="@/assets/img/vertical_logo.svg" alt="" width="240">
        </div>
        <div class="btn-holder">

          <b-btn class="btn"
                  :disabled="loggingIn"
                  @click="neoLogin"
                  variant="secondary"
          >Login</b-btn>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  data() {
    return {
      username: '',
      password: '',
      submitted: false,
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
  },
  created() {
    // reset login status
    // this.$store.dispatch('authentication/logout');
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      const { username, password } = this;
      const { dispatch } = this.$store;
      if (username && password) {
        dispatch('authentication/login', { username, password });
      }
    },
    neoLogin() {
      const { commit, dispatch } = this.$store;
      Vue.googleAuth().directAccess();
      Vue.googleAuth().signIn(
        googleUser => {
          // things to do when sign-in succeeds
          const userData = googleUser.getAuthResponse();
          if (userData && userData.id_token) {
            dispatch('authentication/neoLogin', userData.id_token);
          } else {
            let error = 'Wrong structure, token couldn`t be recognized';
            commit('authentication/loginFailure', error);
            dispatch('alert/error', error, { root: true });
          }
        },
        function(error) {
          // things to do when sign-in fails
          commit('authentication/loginFailure', error);
          dispatch('alert/error', error, { root: true });
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../assets/_scss/dashboard/_component-base";

  .jumbotron {
    height: 100vh;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  .form-holder {
    width: 490px;
    max-width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 66px;
    border-radius: 6px;
    transform: translateY(-30%);
  }

  .btn-holder {
    display: flex;
    justify-content: center;
  }
  .btn {
    margin-top: 30px;
    height: 52px;
    font-size: 20px;
    font-weight: 500;
    min-width: 100px;
  }
</style>
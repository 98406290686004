import Vue from 'vue';
import Vuex from 'vuex';

import { app } from './app.module';
import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { users } from './users.module';
import { links } from './links.module';
import { refs } from './refs.module';
import { filter } from './filter.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    app,
    alert,
    authentication,
    users,
    links,
    refs,
    filter,
  },
  state: {
    packageVersion: JSON.parse(
      unescape(process.env.PACKAGE_JSON || '%7Bversion%3A0%7D')
    ).version,
  },
  getters: {
    appVersion: state => {
      return state.packageVersion;
    },
  },
});

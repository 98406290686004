import { refsService } from '../_services';

export const refs = {
  namespaced: true,
  state: {
    agencies: {},
    topic_areas: {},
    content_formats: {},
    newItem: {},
  },
  actions: {
    getAll({ commit }) {
      commit('getAllRequest');
      refsService.get().then(
        response => commit('getAllSuccess', response),
        error => commit('getAllFailure', error)
      );
    },
    newItem({ commit }, payload) {
      commit('newItemRequest', payload);
      refsService.newItem(payload).then(
        response => commit('newItemSuccess', response),
        error => commit('newItemFailure', error)
      );
    },
    updateItem({ commit }, payload) {
      refsService.updateItem(payload).then(
        response => commit('updateItemSuccess', response)
      );
    },
    updateRefAfterInsert({ commit }, data) {
      commit('updateRefAfterInsert', data);
    },
  },
  mutations: {
    getAllRequest(state) {
      state.all = { loading: true };
      state.isDataLoaded = false;
    },
    newItemRequest(state) {
      state.all = { loading: true };
      state.isDataLoaded = false;
    },
    clearNewRefItem(state) {
      state.newItem = {};
    },
    getAllSuccess(state, data) {
      state.topic_areas = data.topic_areas || [];
      state.agencies = data.agencies || [];
      state.content_formats = data.content_formats || [];
      state.isDataLoaded = true;
    },
    newItemSuccess(state, data) {
      state[data[0].refName] = data[1];
      state.newItem = data;
      state.isDataLoaded = true;
    },
    updateItemSuccess(state, data) {
      state[data[0].refName] = data[1];
      state.isDataLoaded = true;
    },
    updateRefAfterInsert(state, data) {
      // debugger
      state[data.refId] = data.refData.data[1];
    },
    getAllFailure(state, error) {
      state.all = { error };
    },
  },
};

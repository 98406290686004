import { apiUrl } from 'config';
import axios from '../_helpers/axios';

export const refsService = {
  get,
  newItem,
  updateItem,
};

function get() {
  return axios.get(`${apiUrl}/api/refs/get`,{params:{refs:'clients,topic_areas,content_formats,agencies'}});
}

function newItem(data) {
  const requestOptions = {
    refName: data.refName,
    refData: data.refData,
  };
  return axios.post(`${apiUrl}/api/refs/new`, requestOptions);
}

function updateItem(data) {
  const requestOptions = {
    refName: data.refName,
    refData: data.refData,
  };
  return axios.post(`${apiUrl}/api/refs/save`, requestOptions);
}

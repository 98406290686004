import { userService } from '../_services';
import { router } from '@/_helpers/router';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    neoLogin({ dispatch, commit }, id_token) {
      commit('loginRequest', {});
      userService.neoLogin({ id_token: id_token }).then(
        user => {
          if (!user) {
            commit('loginFailure');
            return
          };
          commit('loginSuccess', user);
          dispatch('app/runApp', {}, { root: true });
          dispatch('refs/getAll', {}, { root: true });
          router.push('/');
        },
        error => {
          commit('loginFailure', error);
          dispatch('alert/error', error, { root: true });
        }
      );
    },
    logout({ commit }) {
      userService.logout();
      commit('logout');
      router.push('/login');
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
  },
};

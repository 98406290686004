<template>
  <div class="value-item mb-2">
    <label :for="id" v-if="valueTitle">{{valueTitle}}:</label>
    <div class="input-holder" :class="{'with-button':addonIcon}">
      <template v-if="isLink">
        <a :href="value" target="_blank">{{value}}</a>
      </template>
      <template v-else>
        <template v-if="inputType==='select'">
          <b-form-select :id="id"
                         :options="optionsForSelect"
                         v-bind:value="value"
                         v-on:input="emitValue"
                         :disabled="!editable"
          />
          <!--@blur.native="dddd"-->
        </template>
        <template v-if="inputType==='tag-input'">
          <vue-tags-input :id="id"
                          v-model="tag"
                          :tags="tags"
                          :add-only-from-autocomplete="true"
                          :autocomplete-items="tagsForSelect"
                          @tags-changed="emitTagsChanged"
                          :placeholder="placeholder"
          />
        </template>
        <template v-if="inputType==='text'">
          <b-form-input
                  :id="id"
                  type="text"
                  v-bind:value="value"
                  v-on:input="emitValue"
                  :placeholder="placeholder"
                  :disabled="!editable"
          />
        </template>
        <template v-if="inputType==='date'">
          <b-form-input
                  :id="id"
                  type="date"
                  v-bind:value="value"
                  v-on:input="emitValue"
                  :placeholder="placeholder"
                  :disabled="!editable"
          />
        </template>
        <button v-if="addonIcon && editable" type="button" class="flex-shrink-0" @click="callAction">
          <i :class="'icon-'+addonIcon"></i>
        </button>
      </template>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'ValueItem',
    components: {
      'vue-tags-input': () => import('@johmun/vue-tags-input'),
    },
    data: function () {
      return {
        id: null,
        tag: '',
        tags: [],
      };
    },
    props: {
      tagItems: {
        type: Array,
      },
      selectOptions: {
        type: Array,
      },
      inputType: {
        type: String,
        default: 'select',
      },
      value: {
        required: true,
      },
      valueTitle: {
        type: String,
      },
      hasRefresh: {
        type: Boolean,
        default: false,
      },
      editable: {
        type: Boolean,
        default: false,
      },
      isLink: {
        type: Boolean,
        default: false,
      },
      addonIcon: {
        type: String,
      },
      placeholder: {
        type: String,
      },
    },
    created() {
      this.localValue = this.extVModel;
    },
    mounted() {
      this.id = 'input_' + this._uid;
    },
    watch: {
      localValue: {
        handler() {
          this.$emit('input', this.localValue);
        },
      },
    },
    methods: {
      dddd: function (ev) {
        // console.log('dddd');
        // alert('dd')
        // debugger
        // var event = new Event('blur');
        //
        // this.$el.dispatchEvent(event);
        // this.$emit('blur2');
      },
      callAction: function () {
        this.$emit('addonAction');
      },
      emitValue: function (e, v) {
        this.$emit('input', e, v);
      },
      emitTagsChanged: function (newTags) {
        this.tags = newTags;
        this.$emit('input', newTags);
      },
    },
    computed: {
      optionsForSelect() {
        // debugger
        return !this.selectOptions
          ? []
          : this.selectOptions.map(e => {
            return Object.assign(e, {
              value: e.value ? e.value : e.id,
              text: e.text ? e.text : e.name,
            });
          });
      },
      tagsForSelect() {
        let allItems = !this.tagItems
          ? []
          : this.tagItems.map(e => {
            return Object.assign(e, {
              text: e.text ? e.text : e.name,
            });
          });
        return allItems.filter(i => {
          return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
        });
      },
    },
  };
</script>

<style lang="scss">
  @import "../assets/_scss/dashboard/_component-base";

  a {
    max-width: 100%;
    overflow: hidden;
  }

  .value-item {
    .input-holder {
      display: flex;

      .vue-tags-input {
        flex-grow: 1;
      }

      input {
        &:focus {
          border-color: $lgrey5;
        }
      }
      &.with-button {
        input,
        select {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          border-right: none;
          outline: none;
          box-shadow: none;
        }

        button {
          border-bottom-right-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
          background: #fff;
          cursor: pointer;
          border: 1px solid $lgrey5;
          border-left: none;
        }
      }
    }

  }
</style>

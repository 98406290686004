import {linksService} from "../_services";
import axios from "../_helpers/axios";
import sse from "../_helpers/sse";
import {apiUrl} from "config";
import Vue from "vue"
import {ToastPlugin} from "bootstrap-vue"

Vue.use(ToastPlugin)

export const links = {
  namespaced: true,
  state: {
    all: {},
    saved: null,
    serverProcessing: false,
    selectedPage: 1,
    search: '',
    options: {
      portionSize: 12,
      filters: {},
      sortOption: {}
    },
    data: [],
    selectionData: [],
    curPage: 0,
    totalPages: 0,
    eof: false,
    loadingState: null
  },
  actions: {
    resetData({commit,dispatch}, payload) {
      commit('doResetData', payload)
      dispatch('getPortion')
    },
    getAll({commit}) {
      commit("getAllRequest");
      linksService.getAll().then(
          response => commit("getAllSuccess", response)
      );
    },
    getSelection({commit,state}) {
      commit("startServerProcessing")
      linksService.getPortion(-1,state)
          .then(response => commit("getSelectionSuccess", response))
          .finally(() => {
            commit("stopServerProcessing")
          })
    },
    getPortion({state,commit}, {page='next'}={}) {
      if (state.eof || state.serverProcessing)
        return []

      if (page === 'next' || !page) {
        page = state.curPage + 1
      }

      commit("startServerProcessing")
      linksService.getPortion(page,state)
        .then(response => {
          commit("getPortionSuccess", response)
          return response && response.length
        })
        .then(result => {
          if (state.loadingState) {
            if (result) {
              state.loadingState.loaded()
            } else {
              state.loadingState.complete()
            }
          }
        })
        .finally(() => {
          commit("stopServerProcessing")
        })
    },
    save({commit}, data) {
      commit("saveRequest");
      let linksData = data;

      linksService.save(linksData).then(
          response => commit("saveSuccess", response)
      );
    },
    async addLinks(context, urls) {
      const {commit} = context
      let progressInfo = {
        sections: {
          progress: 0,
          max: 100,
          operation: "",
        },
        subsection: {
          progress: 0,
          max: 100,
          operation: "",
        },
      };
      commit("app/bgProcessStart", progressInfo, {root: true});
      const requestOptions = {
        application: 0,
        urls: urls
      };
      await axios.post(`${apiUrl}/api/prepareLongRequest`);
      const sseClient = await sse.subscribe((data) => {
        let path = data.path || "";
        let pathArr = path.split("/").filter(e => e);

        if (data.error) {
          console.error(data.error, data.data)
          const $bvToast = this._vm.$bvToast
          if ($bvToast) {
            $bvToast.toast(data.error, {
              title: "Error",
              variant: "danger",
              toaster: "b-toaster-bottom-left",
              solid: true,
              autoHideDelay: 15000,
              appendToast: true,
            })
          }

          if (pathArr.length === 2) {
            progressInfo.subsection.progress = data.progress;
          }
          return
        }

        if (data.event === "start") {
          if (pathArr.length === 1) {
            //root process started
            progressInfo.sections.max = data.total;
          } else if (pathArr.length === 2) {
            //subprocess started
            progressInfo.sections.operation = data.descr;
            progressInfo.subsection.progress = 0;
            progressInfo.subsection.max = data.total;
          }
        } else if (data.event === "end") {
          if (pathArr.length === 2) {
            progressInfo.sections.progress += 1;
          }
        } else if (data.event === "success") {
          if (pathArr.length === 2) {
            progressInfo.subsection.operation = data.obj;
            progressInfo.subsection.progress = data.progress;
          }
        }

        commit("app/bgProcessUpdate", progressInfo, {root: true});

      });

      let ahrefs = await axios.post(`${apiUrl}/api/links/addURLS`, requestOptions);

      // commit('alert/info',`Added ${ahrefs.length} links`,{root: true});
      commit("app/bgProcessEnd", undefined, {root: true});

      sseClient.disconnect()

    },
  },
  mutations: {
    doResetData(state, {loadingState, options, filters, search}={}) {
      if (loadingState) {
        state.loadingState = loadingState
        loadingState.reset()
      }
      state.options = options || {}
      state.options.filters = filters || state.options.filters

      state.search = search
      state.data = []
      state.selectionData = []
      state.curPage = 0
      state.totalPages = 0
      state.eof = false
    },
    startServerProcessing(state) {
      state.serverProcessing = true
    },
    stopServerProcessing(state) {
      state.serverProcessing = false
    },
    getPortionSuccess(state, data) {
      if (!data.length) {
        state.eof = true
        return
      }
      state.data = state.data.concat(data)
      state.curPage++
    },
    addSelection(state,item) {
      if (Array.isArray(item)) {
        state.selectionData = state.selectionData.concat(item)
      } else {
        if (!state.selectionData.find(e => e.id === item.id)) {
          state.selectionData.push(item);
        }
      }
    },
    removeSelection(state,item) {
      if (!item) {
        state.selectionData = []
      } else {
        state.selectionData.remove(
            state.selectionData.find(e => e.id === item.id)
        );
      }
    },
    clearSelection(state) {
      state.selectionData = []
    },
    getSelectionSuccess(state, data) {
      if (!data.length) {
        return
      }
      state.selectionData = data
    },
    getAllRequest(state) {
      state.all = {loading: true};
      state.serverProcessing = true;
      state.isDataLoaded = false;
    },
    setSelectedPage(state, page) {
      state.selectedPage = page;
    },
    getAllSuccess(state, data) {
      state.all = data;
      state.serverProcessing = false;
      state.isDataLoaded = true;
    },
    getAllFailure(state, error) {
      state.all = {error};
      state.serverProcessing = false;
    },
    saveRequest(state) {
      state.serverProcessing = true;
    },
    updateItem(state, data) {
      let tmpObj = data;
      if (Array.isArray(tmpObj)) {
        tmpObj = tmpObj[tmpObj.length - 1][0];
      }
    },
    saveSuccess(state, data) {
      let updatedData = data;
      if (Array.isArray(updatedData)) {
        updatedData = updatedData[updatedData.length - 1];
      }

      updatedData.map(el => {
        state.data.map(e => {
          if (e.id === el.id) {
            Object.assign(e, el);
          }
        });

      });
      state.saved = true;
      state.serverProcessing = false;
    },
    saveResetStatus(state) {
      state.saved = null;
    },
    saveFailure(state, error) {
      state.serverProcessing = false;
    },
  },
};

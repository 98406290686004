import { apiUrl } from 'config';
import axios from '../_helpers/axios';

export const linksService = {
  getAll,
  getPortion,
  getData,
  save,
};

function getData(id) {
  return axios.get(`${apiUrl}/api/links/getData`, { params: { id: id } });
}

function getAll() {
  return axios.get(`${apiUrl}/api/links?application=0`);
}

function getPortion(page, state) {
  const portionSize = state.options?.portionSize || 10

  const filterAge = state.options?.filters?.age
  const filterAgeQuery = filterAge ? `&fAge=${filterAge}` : ''

  const filterContentType = state.options?.filters?.contentType
  const filterContentTypeQuery = filterContentType ? `&fFormat=${filterContentType}` : ''

  const filterTopicArea = state.options?.filters?.topicArea
  const filterTopicAreaQuery = filterTopicArea ? `&fTopic=${filterTopicArea}` : ''

  const filterAgency = state.options?.filters?.agency
  const filterAgencyQuery = filterAgency ? `&fAgency=${filterAgency}` : ''

  const filterSearch = state.options?.search
  const filterSearchQuery = filterSearch ? `&fSearch=${filterSearch}` : ''

  const sortOptionField = state.options?.sortOption?.field
  let sortOptionQuery = sortOptionField ? `&sField=${sortOptionField}` : ''

  const sortOptionDesc = state.options?.sortOption?.desc
  sortOptionQuery += sortOptionDesc ? `&sDesc=${sortOptionDesc}` : ''

  return axios.get(`${apiUrl}/api/links?application=0${filterAgeQuery}${filterContentTypeQuery}${filterTopicAreaQuery}${filterAgencyQuery}${filterSearchQuery}${sortOptionQuery}`);
  // return axios.get(`${apiUrl}/api/links?application=0&p=${page}&ps=${portionSize}${filterAgeQuery}${filterContentTypeQuery}${filterTopicAreaQuery}${filterAgencyQuery}${filterSearchQuery}${sortOptionQuery}`);
}

async function save(data) {
  return await axios.post(`${apiUrl}/api/links/save`, data)
}

import axios from 'axios';
import { apiUrl } from 'config';
import { authHeaders, processApiError } from '../_helpers';

const instance = axios.create({
  baseURL: apiUrl,
});

instance.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response && error.response.status === 401) {
      processApiError(error);
    } else if (error.response && error.response.data) {
      processApiError(error.response.data);
    }
    return Promise.reject();
  });

instance.interceptors.request.use(config => {
  config.headers = { ...config.headers, ...authHeaders() };
  return config;
});

export default instance;

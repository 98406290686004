<template>
  <div class="page-wrapper" :class="{'modal-open':isSomeModalOpen,'filters-open':showFilters}">
    <div class="fixed-part" ref="fixedPart">
      <page-header></page-header>

      <b-container fluid class="outer overflow-x-hidden controls-wrapper" ref="controls">
        <div class="sidebar-holder mobile-modal mm-" :class="{show:showFilters}"
             v-click-outside="toggleFilterPanel">
          <filter-panel
                  @changed="filterChanged"
                  @closePanel="showFilters=false"
          ></filter-panel>
        </div>

        <div class="body-holder" :class="{'modal-open':isSomeModalOpen}">
          <b-navbar class="db-action-panel">
            <b-btn variant="primary"
                   class="filters-toggler"
            >
              <span><i :class="showFilters?'icon-hide-filters':'icon-show-filters'"></i></span>
              <span class="d-none d-sm-inline ml-2">{{showFilters?'Hide':'Show'}} filters</span>
            </b-btn>
            <div class="db-search-holder">
              <b-form-input v-model="searchText"
                            placeholder="Awesome search that looks for all elements"
              ></b-form-input>

              <div class="ico-holder">
                <img src="@/assets/img/ico-magnifier.png" alt="" height="16">
              </div>
            </div>

            <div class="d-flex">
              <b-btn :disabled="URLsListLoading"
                     @click="$refs.uploadLinksForm.showModal()"
                     class="ml-3"
              >
                <i v-if="URLsListLoading" class="icon-update-metrics spin"> </i>
                <i v-else class="icon-arrow-up"></i>
                <span class="d-none d-sm-inline ml-2">Upload New</span>
              </b-btn>
            </div>
          </b-navbar>

          <div class="db-grid-header hide-on-body-scrolled">
            <div >
              <b-form-checkbox
                      v-model="selectAll"
                      name="selectAllCheckbox"
                      value="*"
                      unchecked-value="-"
                      @change="toggleAllSelection"
              >
                <span class="d-none d-sm-inline ml-4 text-nowrap">Select All for Edit</span>&nbsp;
              </b-form-checkbox>
            </div>

            <Pagination v-if="totalPages" :total-pages="totalPages" :selected-page="selectedPage" @change="paginate"/>

            <div class="sort-holder d-flex flex-column">
              <b-dropdown id="ddown1" no-caret>
                <template slot="button-content">
                  <span>Sort by:</span>
                  <u>{{sortOptionName}}</u>
                  <i class="icon-bracket-down"></i>
                </template>
                <template v-for="option in sortOptions">
                  <b-dropdown-item @click="sortOption=option" :key="option.id">{{option.name}}</b-dropdown-item>
                </template>
              </b-dropdown>
            </div>
          </div>

          <section v-if="somethingSelected">
            <div class="db-bulk-panel">
              <bulk-operations
                      :items="selectedItems"
                      @done="bulkOpDone"
                      @deselect="deselectItems"
              ></bulk-operations>
            </div>
          </section>
        </div>
      </b-container>
    </div>

    <b-container fluid class="outer content-holder">
      <div v-if="linksLoading" class="project-spinner spinner-holder">
        <spinner
                :animation-duration="1000"
                :size="100"
                :color="'#e7553d'"
        ></spinner>
      </div>

      <div class="items-holder-wrapper">
        <section
            class="items-holder"
            ref="itemsHolder"
            :class="somethingSelected?'selection-mode':''"
        >
          <template>
            <b-row ref="itemsList">
              <div v-for="item in filteredLinksActive"
                   :key="item.id"
                   :data-id="item.id"
                   class="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex mb-4"
              >
                <div class="item-holder ih-" :class="isItemSelected(item)?'selected':''">
                  <div class="ih-header" @click.stop.prevent="itemCheckAction(item,!isItemSelected(item))">
                    <div class="d-flex align-items-center flex-grow-1">
                      <div class="ih-links-counter" v-b-tooltip.hover :title="'RDS'">
                        <i class="icon-links mr-1"></i>
                        <span class="c-azure">{{item.rds}}</span>
                      </div>
                      <div class="flex-grow-1"></div>
                      <div class="ih-select-item">
                        <b-form-checkbox
                                v-model="item.selected"
                                name="selectItemCheckbox"
                                class="p-0 no-label"
                                readonly
                        ></b-form-checkbox>
                      </div>
                    </div>
                    <div>

                    </div>
                  </div>

                  <div class="img-holder ih-img-holder" @click="editLink(item.id)">
                    <div v-if="item.thumbnail">
                      <img :src="item.thumbnail | addImgAddress" alt="">
                    </div>
                    <div v-else>
                      <img src="@/assets/img/no-image.jpg" alt="">
                    </div>
                    <div class="ih-img-overlay"></div>
                  </div>

                  <div class="ih-footer">
                    <p class="ih-title" @click="editLink(item.id)">{{item.title}}</p>

                    <p class="flex-grow-1">
                      <a :href="item.url" class="c-azure tdu" target="_blank">{{item.url}}</a>
                    </p>
                    <hr>
                    <div class="d-flex justify-content-between">
                      <div>
                        <p class="c-grey mb-1">Agency</p>
                        <p class="c-dark">{{item.agency_name}}</p>
                      </div>

                      <div class="mx-3">
                        <p class="c-grey mb-1">Topic</p>
                        <p class="c-dark">{{item.topic_area_name}}</p>
                      </div>

                      <div>
                        <p class="c-grey mb-1">Type</p>
                        <p class="c-dark">{{item.content_format_name}}</p>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </b-row>
          </template>

          <Pagination v-if="totalPages" :total-pages="totalPages" :selected-page="selectedPage" @change="paginate"/>
        </section>
      </div>
    </b-container>


    <b-modal ref="addLinks"
             id="addLinks"
             hide-footer
             @shown="URLsList=''"
             title="Add the URLs"
    >
      <div>
        <textarea name="coverageURLsList"
                  id="coverageURLsList"
                  v-model="URLsList"
                  rows="10"
                  placeholder="Paste list of URLs, each on a new line"
                  class="w-100"
        ></textarea>
      </div>
      <div class="d-flex justify-content-around">
        <b-btn @click="analyzeURLs">Add</b-btn>
        <b-btn @click="$refs.addLinks.hide()">Cancel</b-btn>
      </div>
    </b-modal>

    <upload-links ref="uploadLinksForm" @done="linksInit"></upload-links>

    <b-modal ref="newItemModal"
             @shown="inputFocus"
             hide-footer
             title="Create new reference item"
    >
      <form @submit.stop.prevent="newItemAdd">
        <value-item
                v-bind:value="newRefItemName"
                v-on:input="newRefItemName = $event"
                :inputType="'text'"
                :valueTitle="newRefTitle"
                :editable="true"
        ></value-item>
      </form>
    </b-modal>

    <b-modal ref="deleteLinkConfirm"
             id="deleteLinkConfirm"
             @shown="inputFocus"
             hide-footer
             title="Are you sure you want to delete this link?"
    >
      <p v-if="itemToDelete">{{itemToDelete.title}}</p>
      <div class="d-flex justify-content-around mt-4">
        <b-btn @click="deleteLink">Yes</b-btn>
        <b-btn @click="$refs.deleteLinkConfirm.hide()">No</b-btn>
      </div>
    </b-modal>

    <item-edit ref="linkEdit"
               :id="updateItemId"
               @saved="linkSaved"
    >
    </item-edit>
  </div>

</template>

<script>
import axios from '../_helpers/axios';
import {apiUrl, imagesHost} from 'config';
import moment from 'moment';
import {SwappingSquaresSpinner} from 'epic-spinners';
import InfiniteLoading from 'vue-infinite-loading';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

export default {
  components: {
    'page-header': () => import('@/components/Header.vue'),
    'value-item': () => import('@/components/ValueItem.vue'),
    'upload-links': () => import('@/components/UploadLinks.vue'),
    'bulk-operations': () => import('@/components/BulkOperations.vue'),
    'item-edit': () => import('@/components/ItemEdit.vue'),
    'filter-panel': () => import('@/components/FilterPanel.vue'),
    'Pagination': () => import('@/components/Pagination'),
    spinner: SwappingSquaresSpinner,
    // InfiniteLoading
  },
  data: () => ({
    selectedPage: 1,
    totalPages: 1,
    rowsPerPage: 12,
    URLsListLoading: false,
    URLsList: [],
    showControls: null,
    searchText: '',
    itemToDelete: null,
    counterLoading: false,
    showRefreshLink: 0,
    showTopicAreaEdit: 0,
    showAgencyNameEdit: 0,
    newRefItemName: '',
    newRefTitle: '',
    newRefName: '',
    newRefItemId: undefined,
    selectedMap: [],
    sortOptions: [
      {
        id: 0,
        field: 'title',
        name: 'Title',
        desc: false,
      },
      {
        id: 1,
        field: 'rds',
        name: "Links",
        desc: true,
      },
      {
        id: 2,
        field: 'creation_date',
        name: 'Creation date',
        desc: true,
      },
    ],
    sortOption: 1,
    updateItemId: -1,
    selectAll: '-',
    showFilters: false,
    filters: {},
    showBulkPanel: false,
    modalOpen: false,
    fixedPart: null,
    fixedPartHeight: 0,
    infiniteLoadingState: null,
    scrolledTop: 0,
    scrollStart: 0,
    scrolledDistance: 0,
    bodyScrolled: false
  }),
  filters: {
    addImgAddress(val) {
      return imagesHost + val;
    },
  },
  created() {
    this.$store.dispatch('links/resetData',this.resetDataOptions);

    this.$nextTick(()=>{
      const itemsHolder = this.$refs.itemsHolder
      const itemsList = this.$refs.itemsList
      if (itemsHolder) {
        const handleScroll = throttle((ev) => {
          if ((ev.target.scrollTop+itemsHolder.clientHeight)>itemsList.clientHeight) return;

          const scrollDirection = this.scrolledTop < ev.target.scrollTop ? 1 : -1
          this.scrolledTop = ev.target.scrollTop
          if (scrollDirection===-1) {
            this.bodyScrolled = false
            this.scrolledDistance = 0
            this.scrollStart = 0
          } else if (!this.bodyScrolled) {
            if (this.scrollStart===0) {
              this.scrollStart = this.scrolledTop
            }
            this.scrolledDistance = this.scrolledTop - this.scrollStart
            if (this.scrolledDistance>100) {
              this.bodyScrolled = true
            }
          }

        }, 100)
        itemsHolder.addEventListener('scroll', handleScroll);
      }


    });
  },
  mounted: function () {
    document.body.classList.add('dashboard');
    document.body.classList.add('db-');

    this.sortOption = this.sortOptions[1];
  },
  destroyed() {
    document.body.classList.remove('dashboard');
    document.body.classList.remove('db-');
  },
  methods: {
    paginate(val) {
      this.selectedPage = val
    },
    searchLinks() {
      if (this.$store.state.links.options.search===this.searchText) {
        return
      }
      this.$store.dispatch('links/resetData',this.resetDataOptions);
    },
    infiniteHandler($state) {
      if (!this.$store.state.links.data.length) {
        this.$store.dispatch(
            'links/resetData',
            {...this.resetDataOptions, loadingState: $state}
        );
      } else {
        this.$store.dispatch('links/getPortion');
      }
    },
    ahrefsLink(url) {
      return (
        'https://app.ahrefs.com/site-explorer/overview/v2/prefix/recent?target=' +
        encodeURIComponent(url)
      );
    },
    filterChanged(f) {
      this.filters = f;
      // this.$store.dispatch('links/resetData',this.resetDataOptions);
    },
    closeBulkPanel() {
      this.showBulkPanel = false;
    },
    toggleFilterPanel(e) {
      const isToggler = e.path.reduce((acc,e)=>{
        return acc || (e.classList && e.classList.contains('filters-toggler'));
      },false);

      if (isToggler || this.showFilters) {
        this.showFilters = !this.showFilters;
      }
    },
    linksInit() {
      this.$store.dispatch('links/resetData',this.resetDataOptions);
    },
    editLink(itemId) {
      this.updateItemId = itemId;
      this.$refs.linkEdit.open();
    },
    linkSaved() {
      this.$forceUpdate();
    },
    deselectItems() {
      this.$store.commit('links/clearSelection');
      this.selectAll = '-';
    },
    bulkOpDone() {
      this.$store.commit('links/clearSelection');
      this.linksInit();
      this.closeBulkPanel();
      this.deselectItems();
    },
    toggleAllSelection() {
      // if (this.selectAll === '-') {
      //   this.$store.commit('links/clearSelection');
      // } else {
      //   this.$store.dispatch('links/getSelection',{filters: this.filters, search: this.searchText});
      // }
      if (this.selectAll === '-') {
        this.$store.commit('links/removeSelection');
      } else {
        this.$store.commit('links/addSelection',this.filteredLinks);
      }
    },
    itemCheckAction(item, val) {
      item.selected = val;
      if (val) {
        if (this.selectedMap.indexOf(item.id)===-1) {
          this.$store.commit('links/addSelection',item);
        }
      } else {
        this.$store.commit('links/removeSelection',item);
      }
    },
    updateRD(id) {
      this.counterLoading = true;

      axios
        .post(`${apiUrl}/api/links/updateMetrics`, {
          timeout: 1000 * 60 * 10,
          data: {
            id: id,
          },
        })
        .then(resp => {
          this.$store.dispatch('alert/info', 'Metrics refreshed', {
            root: true,
          });
          console.log('RD updated', resp);
          this.$store.commit('links/saveSuccess', resp);
        })
        .finally(() => {
          this.counterLoading = false;
        });
    },
    async analyzeURLs() {
      const requestOptions = {
        urls: this.URLsList.split('\n'),
      };
      this.URLsListLoading = true;

      await axios.post(`${apiUrl}/api/prepareLongRequest`);

      let links = await axios
        .post(`${apiUrl}/api/links/addURLS`, requestOptions)
        .finally(() => {
          this.URLsListLoading = false;
          this.linksInit();
        });
      return links;
    },
    async saveLink(item) {
      await axios
        .post(`${apiUrl}/api/links/save`, item)
        .then(resp => {
          console.log('getReportData', resp);
          item.needSave = false;
        });
    },
    async deleteLink() {
      await axios
        .post(`${apiUrl}/api/links/delete`, this.itemToDelete)
        .then(resp => {
          console.log('getReportData', resp);
          this.itemToDelete.status = 2;
        })
        .finally(() => {
          this.$refs.deleteLinkConfirm.hide();
        });
    },
    firstTab() {
      if (this.$refs.tabs) return this.$refs.tabs.currentTab === 0;
    },
    newRefItem(newRefName, newRefTitle) {
      this.newRefItemName = '';
      this.newRefName = newRefName;
      this.newRefTitle = newRefTitle;
      this.$refs.newItemModal.show();
    },
    inputFocus() {
      this.$refs.newItemModal.$el.querySelector('input').focus();
    },
    newItemAdd() {
      console.log('new ref item in ' + this.newRefName, this.newRefItemName);
      this.$store.dispatch('refs/newItem', {
        refName: this.newRefName,
        refData: {name: this.newRefItemName},
      });
      this.$refs.newItemModal.hide();
    },
    formatDate(val) {
      return val ? moment(val).format('DD MMM YYYY') : '';
    },
    isItemSelected(item) {
      return item && this.selectedMap.indexOf(item.id)>=0
    },
  },
  computed: {
    resetDataOptions() {
      return {
        options: {
          filters: this.filters,
          sortOption: this.sortOption,
          search: this.searchText
        }
      }
    },
    selectedItems() {
      return this.$store.state.links.selectionData;
    },
    linksLoading() {
      return this.$store.state.links.serverProcessing || false;
    },
    isSomeModalOpen() {
      return this.showBulkPanel;
    },
    somethingSelected() {
      return !!this.selectedItems.length;
    },
    linkItems() {
      return this.$store.state.links.data || [];
    },
    filteredLinks() {
      let allItems = this.linkItems || [];

      if (allItems && allItems.length) {
        if (+this.filters.age) {
          const limitDate = moment().subtract(+this.filters.age, 'years');
          // console.log("limitDate",limitDate);
          allItems = allItems.filter(e => {
            let fsd = moment(e.date_creation);
            if (isNaN(fsd.diff(limitDate))) {
              return false;
            }
            return fsd.diff(limitDate) >= 0;
          });
        }

        if (this.filters.contentType) {
          allItems = allItems.filter(e => e.content_format_id===this.filters.contentType);
        }

        if (this.filters.topicArea) {
          allItems = allItems.filter(e => e.topic_area_id===this.filters.topicArea);
        }

        if (this.filters.agency) {
          allItems = allItems.filter(e => {
            return e.agency_id === this.filters.agency;
          });
        }

        if (this.searchText) {
          const searchRx = new RegExp(this.searchText,'ig')
          console.log("this.searchText",this.searchText);
          allItems = allItems.filter(i => i.title.match(searchRx) || i.url.match(searchRx));
        }

        const so = this.sortOption;
        if (so) {
          allItems = allItems.sort((a, b) => {
            if (a[so.field] > b[so.field]) {
              return so.desc ? -1 : 1;
            }
            if (a[so.field] < b[so.field]) {
              return so.desc ? 1 : -1;
            }
            return 0;
          });
        }

      }

      return allItems
    },
    totalActiveLinks() {
      const allItems = this.filteredLinks
      return allItems.length
    },
    filteredLinksActive() {
      const allItems = this.filteredLinks

      const begin = (this.selectedPage - 1) * this.rowsPerPage;
      const res = allItems.slice(begin, begin + this.rowsPerPage);
      return res
    },
    sortOptionName() {
      if (this.sortOption) return this.sortOption.name;
      return '';
    },
    user() {
      return this.$store.state.authentication.user;
    },

  },
  watch: {
    bodyScrolled(newVal) {
      const controlsRef = this.$refs.controls
      if (!controlsRef) {
        return
      }
      if (newVal) {
        this.$refs.fixedPart.classList.add('body-scrolled');
        controlsRef.style.marginTop = `-${controlsRef.clientHeight}px`
      } else {
        this.$refs.fixedPart.classList.remove('body-scrolled');
        controlsRef.style.marginTop = ''
      }
    },
    linkItems(newVal) {
      newVal.forEach(itm => {
        itm.selected = this.selectedMap.indexOf(itm.id)>=0
      })
    },
    selectedItems(newVal) {
      this.selectedMap = newVal.map(e => e.id)
      this.filteredLinksActive.forEach(itm => {
        itm.selected = this.selectedMap.indexOf(itm.id)>=0
      })
    },
    sortOption() {
      this.selectedPage = 1;
    },
    selectedPage: function () {
      if (
        this.selectedPage > 1 &&
        this.$store.state.app &&
        this.$store.state.app.objects['swipefile'] &&
        this.$store.state.app.objects['swipefile'].selectedPage !==
        this.selectedPage
      ) {
        this.$store.commit('app/setSelectedPage', {
          obj: 'swipefile',
          page: this.selectedPage,
        });
      }
    },
    totalPages: function () {
      this.selectedPage = 1;
    },
    totalActiveLinks: function (total) {
      this.totalPages = Math.max(
        1,
        Math.round(total / Math.max(1, this.rowsPerPage))
      );
    },
  },
};
</script>

<style scoped lang="scss">
  $sidebarWidth: 15%;
  @import "../assets/_scss/dashboard/_component-base";
  @import "../assets/_scss/dashboard/components/action-panel";
  @import "../assets/_scss/dashboard/components/sidebar-holder";
  @import "../assets/_scss/dashboard/components/mobile-modal";
  @import "../assets/_scss/dashboard/components/bulk-panel";

  .page-wrapper {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
    overflow-y: hidden;
  }
  .controls-wrapper {
    transition: .3s;
  }
  .content-holder {
    overflow-y: hidden;
  }
  .outer {
    display: flex;
    position: relative;
    //height: 100%;

    .spinner-holder {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      padding-top: 10%;
      height: 100%;
      width: 100%;
      @include sm {
        //position: static;
      }
      &:before {
        content: '';
        display: block;
        z-index: 1;
        background: rgba(0, 0, 0, 0.2);
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  .date-seen {
    display: inline-block;
  }

  .date-creation {
    display: inline-block;
    font-size: 8px;
  }

  .body-holder {
    flex-basis: 100%;
    flex-grow: 1;
    z-index: 999;
    &.modal-open {
      z-index: auto;
    }
  }

  .db-bulk-panel-mobile {
    transition: .3s;
    opacity: 0;
    transform: translateY(-500px);
    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .filters-toggler {
    margin-right: 30px;
  }

  .items-holder.items-holder {
    transition: .3s;
    padding-top: 10px;
    height: 100%;
    overflow-y: auto;
    padding-right: 15px;

    /deep/ .custom-checkbox .custom-control-label {
      &:before, &:after {
        left: -20px;
      }
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(246, 246, 246, .5);
      z-index: -1;
      opacity: 0;
      transition: .3s;
    }
    .btn-report {
      margin-top: 9px;
      align-self: flex-start;
      padding: 6px 13px;
      font-weight: 400;
    }
  }

  .filters-open {
    .body-holder {
      flex-basis: (100% - $sidebarWidth);
      transform: translateX(0);
    }
    .items-holder {
      transform: translateX($sidebarWidth);
      position: relative;
      @include sm {
        transform: none;
        position: static;
      }
      &:before {
        z-index: 50;
        opacity: 1;
      }
    }
    [role="radiogroup"] {
      display: flex;
      flex-direction: column;
    }
  }

  #ddown1 {
    /deep/ .dropdown-menu.show {
      z-index: 99;
    }
  }

  .navbar {
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    background: transparent;
    button.btn {
      height: 100%;
    }
  }

  .value-item {
    margin-bottom: 0 !important;
  }

  hr {
    border-top: 1px solid #E9E8ED;
    padding: 20px 0 0;
    margin: 20px 0 0;
  }

  .lh48 {
    line-height: 48px;
  }

  .link-buttons-holder {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: 5px;
    z-index: 10;
    background: rgba(0, 0, 0, 0.1);
    button,
    .custom-control {
      margin: 15px;
    }
  }

  .refresh-link {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }


</style>

import { router } from './router';
import { store } from '../_store';

export function authHeaders(additionalHeaders) {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('user'));
  let res = {};
  if (user && user.token) {
    res = { Authorization: 'Bearer ' + user.token };
  }

  if (additionalHeaders) {
    res = Object.assign(additionalHeaders, res);
  }

  return res;
}

export function processApiError(error) {
  let additionalInfo = '';
  if (error) {
    if (error.response && error.response.status === 401) {
      router.push('/login');
      return;
    }
    if (error.msg) {
      additionalInfo = error.msg;
    } else if (error.message) {
      additionalInfo = error.message;
    }
    additionalInfo = additionalInfo ? ' (' + additionalInfo + ')' : '';
  }
  const errorMessage =
    error.message || error.error || error.statusText || error;

  store.dispatch('alert/error', errorMessage + additionalInfo, { root: true });
}

import {apiUrl} from 'config';
import axios from '../_helpers/axios';

export const appService = {
  runApp,
  globalPatch,
};

function globalPatch() {
  Array.prototype.remove = function () {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L && this.length) {
      what = a[--L];
      while ((ax = this.indexOf(what)) !== -1) {
        this.splice(ax, 1);
      }
    }
    return this;
  };

  if (!("path" in Event.prototype)) {
    Object.defineProperty(Event.prototype, "path", {
      get: function () {
        var path = [];
        var currentElem = this.target;
        while (currentElem) {
          path.push(currentElem);
          currentElem = currentElem.parentElement;
        }
        if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
          path.push(document);
        }
        if (path.indexOf(window) === -1) {
          path.push(window);
        }
        return path;
      }
    });
  }

}

function runApp() {
  console.log("apiUrl",apiUrl);
  return axios.get(`${apiUrl}/api/runApp`);
}

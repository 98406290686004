import Vue from "vue";
import {apiUrl} from "config";
import SSE from "vue-sse";

Vue.use(SSE, {
  format: "json",
  polyfill: true,
  url: apiUrl,
});

export default {
  subscribe: (callback) => {
    return Vue.$sse.create(`${apiUrl}/sse`)
        .on("error", (err) => console.error("Failed to parse or lost connection:", err))
        .on("message", (msg) => {
          if (typeof callback === "function") {
            callback(msg);
          }
        })
        .connect()
        .catch((err) => console.error("Failed make initial connection:", err))
  },
  close: (sseClient)=> {
    console.log("SSE closing");
    sseClient.disconnect();
  }
}






import { appService } from '../_services';

export const app = {
  namespaced: true,
  state: {
    error: '',
    objects: {},
    isMobile: false,
    bgProcessRunning: false,
    bgProcessTitle: '',
    progressInfo: null
  },
  actions: {
    runApp({ commit }) {
      appService.runApp();
      commit('setSelectedPage', { obj: 'swipefile', page: 1 });
    },
  },
  mutations: {
    bgProcessStart(state,progressInfo) {
      state.bgProcessRunning = true;
      if (progressInfo) {
        progressInfo.sections.progress = 0;
        progressInfo.subsection.progress = 0;
        progressInfo.sections.operation = '';
        progressInfo.subsection.operation = '';
        state.progressInfo =  progressInfo;
      }

    },
    bgProcessEnd(state) {
      state.bgProcessRunning = false;
      state.progressInfo = null;
    },
    bgProcessUpdate(state,payload) {
      state.progressInfo =  payload;
    },
    bgProcessTitle(state, data) {
      state.bgProcessTitle = data;
    },

    setSelectedPage(state, data) {
      if (!state.objects[data.obj]) {
        state.objects[data.obj] = {};
      }
      state.objects[data.obj].selectedPage = data.page;
    },
    setIsMobile(state) {
      console.log('setIsMobile',checkMobile());

      function checkMobile() {
        const flag = document.getElementsByClassName('mobile-flag');
        console.log('checkMobile',flag);

        if (!flag.length) return false;
        return !!flag[0].offsetWidth;
      };
      state.isMobile = checkMobile();
    },
  },
};

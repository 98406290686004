import Vue from 'vue';
import Router from 'vue-router';
import { store } from '@/_store';

import LoginPage from '@/views/LoginPage';
import HomePage from '@/views/HomePage';
import AuthError from '@/views/AuthError';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? '/' : '/',
  routes: [
    { path: '/', component: HomePage },
    { path: '/login', component: LoginPage },
    { path: '*', redirect: '/' },
    {
      name: 'AuthError',
      path: '/auth-error',
      component: AuthError,
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  // console.log('xcg',store.state,store.state.authentication,store.state.authentication.user);
  const userData = store.state.authentication.user;
  // debugger
  const publicPages = ['/login', '/auth-error'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !userData) {
    return next('/login');
  }

  next();
});

import { apiUrl } from 'config';
import { router } from '@/_helpers/router';

export const userService = {
  neoLogin,
  logout,
};

function neoLogin(googleUser) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({...googleUser,app_id:'app_swipefile'}),
  };
  return fetch(`${apiUrl}/googleauth`, requestOptions)
    .then(response => {
      return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if (data?.error?.errorCode==='APP_ACCESS_RESTRICTED') {
            router.push('/auth-error');
            return
          }
          if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            location.reload(true);
          }
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        return data;
      });
    })
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.email_verified) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));

        return user;
      } else {
        throw 'Email is not registered';
      }
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

